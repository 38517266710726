<template>
  <div class="meta-box">
    <div class="title-container title-container-padding">
      <h3 class="title">
        {{
          this.optIn
            ? $t('promotion.firstDepositCashback.deactive.title')
            : $t('promotion.firstDepositCashback.active.title')
        }}
      </h3>
    </div>

    <!-- action btn -->
    <el-row :gutter="20" class="sub-box1">
      <el-col :xs="24">
        <div class="sub-title" :class="{ inactive: this.optIn }">
          {{
            this.optIn
              ? $t('promotion.firstDepositCashback.deactive.desc')
              : $t('promotion.firstDepositCashback.active.desc')
          }}
        </div>
      </el-col>
      <el-col :xs="24" class="button-box opt-button-box">
        <el-button v-if="this.optIn" class="el-button btn-blue long-button" data-testid="opt" @click="onSignOut">
          {{ $t('promotion.firstDepositCashback.button.signout') }}
        </el-button>
        <el-button
          v-else
          class="el-button btn-blue long-button"
          data-testid="opt"
          @click="onActivateCampaign"
          :disabled="this.optIn"
        >
          {{ $t('promotion.firstDepositCashback.button.active') }}
        </el-button>
      </el-col>
    </el-row>

    <!-- CASHBACK requirements -->
    <div v-if="this.optIn" class="title-container-padding">
      <div class="title-container">
        <h3 class="title">
          {{ $t('promotion.firstDepositCashback.require.title') }}
        </h3>
      </div>

      <!-- action btn -->
      <el-row :gutter="20" class="sub-box1">
        <el-col :xs="24">
          <div class="sub-title" :class="{ inactive: this.optIn }">
            {{ $t('promotion.firstDepositCashback.require.desc') }}
          </div>
        </el-col>
        <el-col :xs="24">
          <div class="table-wrapper">
            <div class="table-item">
              <DepositHistory :tableData="depositHistoryData" :account="participateAccount" />
            </div>
          </div>
        </el-col>
        <el-col :xs="24" class="button-box opt-button-box">
          <el-button
            class="el-button btn-blue long-button"
            data-testid="redeem"
            @click="onRedeemCampaign"
            :disabled="!this.redeemable"
          >
            {{ $t('promotion.firstDepositCashback.button.redeem') }}
          </el-button>
        </el-col>
      </el-row>
    </div>

    <!-- description -->
    <el-row :gutter="20" class="sub-box2">
      <el-col :xs="24">
        <!-- how to claim -->
        <Description :title="claimTitle" :descriptions="claimDescriptions" />
      </el-col>
      <el-col :xs="24">
        <!-- eligibility -->
        <Description :title="eligibilityTitle" useSlotForLiTag>
          <li v-for="(item, index) in eligibilityDescriptions" :key="index">
            {{ getEligibilityDescContext(item, index) }}
          </li>
        </Description>
      </el-col>
      <el-col :xs="24" class="agreeTnc-box">
        <i18n path="promotion.firstDepositCashback.tnc">
          <template v-slot:link>
            <a :href="tncLink" target="_blank" data-testid="tnc">
              {{ $t('promotion.firstDepositCashback.link') }}
            </a>
          </template>
        </i18n>
      </el-col>
    </el-row>

    <!-- dialog -->
    <AccountDialog
      :dialogVisible.sync="showAccountDialog"
      :campaignID="campaignID"
      :accountOptions="accountOptions"
      @sendAccountMt4Dto="closeAndOpenTncDialog"
      @close="onCloseAccountDialog"
    />
    <AgreeTnc
      :agreeTncVisible.sync="showAgreeTnc"
      headerText="promotion.firstDepositCashback.dialog.tnc"
      :accountData="accountData"
      @activateSuccess="onActivateSuccess"
      @getOptStatus="getOptStatus"
    />
    <CallbackDialog
      :show.sync="showResultDialog"
      :callbackType="optStatus"
      @closCbDialog="closCbDialog"
      @optout="optSignOut"
      @goToFund="goToFund"
    />
  </div>
</template>

<script>
import { apiCampaignInfo, apiCampaignOpt, apiGetRedeemableAccounts, apiRedeem } from '@/resource';
import { isSuccess } from '@/util/restApi';
import blackList from '@/mixins/blackList';
import Description from './Desciption.vue';
import CallbackDialog from '@/components/promotion/firstDepositCashback/CallbackDialog.vue';
import AccountDialog from './AccountDialog.vue';
import AgreeTnc from './AgreeTnc.vue';
import DepositHistory from '@/components/promotion/firstDepositCashback/DepositHistory.vue';

export default {
  name: 'FundAccount',
  components: {
    CallbackDialog,
    Description,
    AccountDialog,
    AgreeTnc,
    DepositHistory
  },
  props: {
    optIn: {
      type: Boolean,
      default: false
    },
    campaignID: {
      type: Number,
      default: 40
    }
  },
  mixins: [blackList],
  data() {
    return {
      showAccountDialog: false,
      showAgreeTnc: false,
      showResultDialog: false,
      claimTitle: this.$t('promotion.firstDepositCashback.claimTitle'),
      claimDescriptions: this.$t('promotion.firstDepositCashback.claimDescriptions'),
      eligibilityTitle: this.$t('promotion.firstDepositCashback.eligibilityTitle'),
      eligibilityDescriptions: this.$t('promotion.firstDepositCashback.eligibleDescriptions'),
      redeemableAccounts: [],
      optStatus: null, // success, failNotOptout, failOptout
      accountData: null,
      depositHistoryData: [],
      participateAccount: '',
      redeemable: false
    };
  },
  computed: {
    accountOptions() {
      return this.redeemableAccounts.map(({ mt4Account, currency, datasourceId, accountDisplayType }) => {
        let accountType = this.$config.accountTypeMaps[Number(accountDisplayType)] || null;
        let label = accountType ? `${mt4Account} - ${accountType} - ${currency}` : `${mt4Account} - ${currency}`;
        return {
          label,
          value: {
            mt4Account,
            currency,
            datasourceId
          }
        };
      });
    },
    queryData() {
      return {
        campaignId: this.campaignID,
        accountMt4Dto: { ...this.accountData.value }
      };
    },
    tncLink() {
      let tncVersion = this.regulator === 'svg' ? '_net' : '';
      return `https://${this.GLOBAL_REFERRAL_SITE}/pdf/0528/first_deposit_cashback_terms_and_conditions${tncVersion}.pdf`;
    },
    lang() {
      return this.$store.state.common.lang;
    }
  },
  mounted() {
    this.getDepositHistory();
  },
  methods: {
    async refreshEligibleCampaigns() {
      await this.$store.dispatch('promotion/actionGetEligibleCampaigns');
    },
    async onActivateCampaign() {
      try {
        const { data: cbData } = await apiGetRedeemableAccounts(this.campaignID);
        if (isSuccess(cbData)) {
          this.redeemableAccounts = cbData.data;
          this.showAccountDialog = true;
        } else {
          this.closCbDialog();
        }
      } catch (e) {
        this.closeAllDialog();
        return this.showError();
      }
    },
    onCloseAccountDialog() {
      this.showAccountDialog = false;
    },
    closeAndOpenTncDialog(accountMt4Dto) {
      this.accountData = { ...accountMt4Dto, campaignID: this.campaignID };
      this.showAccountDialog = false;
      setTimeout(() => {
        this.showAgreeTnc = true;
      }, 100);
    },
    closCbDialog() {
      this.showResultDialog = false;
      setTimeout(() => {
        if (this.optStatus === 'failOptout' || this.optStatus === 'successRedeem') {
          this.$router.push({ name: 'home' });
        }
        this.optStatus = null;
      }, 300);
    },
    closeAllDialog() {
      this.showAccountDialog = false;
      this.showAgreeTnc = false;
      this.showResultDialog = false;
      this.closCbDialog();
    },
    goToFund() {
      this.getDepositBlacklist();
    },
    async onActivateSuccess() {
      try {
        await this.refreshEligibleCampaigns();
        this.optStatus = 'success';
        this.showResultDialog = true;
        this.$emit('activeOptIn');
      } catch (error) {
        this.closeAllDialog();
        return this.showError();
      }
    },
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    },
    onSignOut() {
      this.optStatus = 'failNotOptout';
      this.showResultDialog = true;
    },
    async optSignOut() {
      try {
        const { data: cbData } = await apiCampaignOpt(this.campaignID, false);
        if (isSuccess(cbData)) {
          // code === 0 means opt-out success
          await this.refreshEligibleCampaigns();
          this.optStatus = 'failOptout';
          this.$emit('deactivateAndQueryData', this.optStatus);
        } else {
          this.closCbDialog();
          return this.showError();
        }
      } catch (e) {
        this.closeAllDialog();
        return this.showError();
      }
    },
    getEligibilityDescContext(item) {
      return item;
    },
    async onSuccessRedeem() {
      await this.refreshEligibleCampaigns();
      this.optStatus = 'successRedeem';
      this.showResultDialog = true;
    },
    async onRedeemCampaign() {
      try {
        const { data: res } = await apiRedeem({
          campaignId: this.campaignID
        });

        if (isSuccess(res)) {
          this.onSuccessRedeem();
        } else if (res?.code !== 500) {
          this.showError();
        }
      } catch (error) {
        this.showError();
      }
    },
    getOptStatus() {
      this.$emit('getOptStatus');
    },
    async getDepositHistory() {
      if (!this.campaignID) return;
      if (this.optIn) return;

      try {
        const { data: result } = await apiCampaignInfo(this.campaignID);
        if (!isSuccess(result)) return;
        const { accountId, redeemable, cumulativeLot, requirementLot, cashbackValue, currency } = result.data;
        this.depositHistoryData = [
          {
            accountId: accountId ?? '',
            tradedLost: cumulativeLot ?? '',
            requireMent: requirementLot ?? '',
            cashBackValue: cashbackValue ?? ''
          }
        ];
        this.redeemable = redeemable || null;

        if (currency) {
          this.participateAccount = currency ? `${currency}` : '';
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>